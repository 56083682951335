import React from 'react'
import ComingSoon from '../../components/comingsoon'

const Blog = () => {
    return (
        <div>
            <ComingSoon/>
        </div>
    )
}

export default Blog;